import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'

import { Layout } from '../../components/Layout'
import { SEO } from '../../components/common/SEO'
import { PcBr } from '../../components/common/PcBr'
import { Image } from '../../components/common/Image'
import { Breadcrumb } from '../../components/common/Breadcrumb'

const IndexPage = () => (
  <Layout>
    <SEO 
      title='軽井沢エリアの薬局シンビ堂の会社案内 | シンビ堂 '
      description='シンビ堂の会社案内です。軽井沢を中心に5店舗を展開しています。従業員数は２０名。地域密着でコミュニケーションを大切にする会社です。地域の皆様の暮らしに信頼を提供するをモットーにしています。求人・転職・お問合せとお気軽にご連絡ください。'
      keywords={['シンビ堂','薬局','軽井沢','会社案内','求人']}
    />
    <h1>会社案内 軽井沢 薬局 調剤薬局 求人 | シンビ堂</h1>

    <Container>
      <BreadcrumbWrapper>
        <Breadcrumb color='white' current='会社概要' previous={{ to: 'about', text: 'わたしたち' }} />
      </BreadcrumbWrapper>
      <Reveal delay={500} animation={Animation.FadeIn}>
        <Title>COMPANY</Title>
      </Reveal>
      <Reveal delay={700} animation={Animation.FadeIn}>
        <SubTitle>株式会社シンビ堂医薬</SubTitle>
      </Reveal>
      <Content>  
        <Reveal delay={900} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>会社名称</DetailTitle>
            <DetailContent>株式会社シンビ堂医薬</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1100} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>資本金</DetailTitle>
            <DetailContent>1000万円</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1300} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>代表者</DetailTitle>
            <DetailContent>干川望</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1500} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>従業員数</DetailTitle>
            <DetailContent>26名（男性10名・女性16人）</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1700} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>所在地</DetailTitle>
            <DetailContent>〒389-0104<br />長野県北佐久郡軽井沢町軽井沢東16-6</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1900} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>店舗</DetailTitle>
            <DetailContent>シンビ堂薬局（軽井沢本店)/シンビ堂借宿薬局/シンビ堂中軽井沢薬局/<br />シンビ堂ドラッグス</DetailContent>
          </DetailWrapper>
        </Reveal>
      </Content>

      <Border />

      <Reveal delay={500} animation={Animation.FadeIn}>
        <Title>COMPANY</Title>
      </Reveal>
      <Reveal delay={700} animation={Animation.FadeIn}>
        <SubTitle>株式会社シンビ堂御代田薬局</SubTitle>
      </Reveal>
      <Content>  
        <Reveal delay={900} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>会社名称</DetailTitle>
            <DetailContent>株式会社シンビ堂御代田薬局</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1100} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>資本金</DetailTitle>
            <DetailContent>900万円</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1300} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>代表者</DetailTitle>
            <DetailContent>干川 愼一郎</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1500} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>所在地</DetailTitle>
            <DetailContent>〒389-0207 長野県北佐久郡御代田町馬瀬口1743<PcBr />ツルヤみよたショッピングパーク</DetailContent>
          </DetailWrapper>
        </Reveal>
        <Reveal delay={1700} animation={Animation.FadeIn}>
          <DetailWrapper>
            <DetailTitle>店舗</DetailTitle>
            <DetailContent>シンビ堂御代田薬局</DetailContent>
          </DetailWrapper>
        </Reveal>
      </Content>

      <MessageContainer>
        <Reveal delay={1900} animation={Animation.SlideInLeft}>
          <ImageWrapper><Image path='president.jpg' /></ImageWrapper>
        </Reveal>
        <Reveal delay={2100} animation={Animation.SlideInRight}>
          <MessageWrapper>
            <div>社長あいさつ</div>
            <div>
            昭和51年、私の両親が軽井沢に1店舗の薬店を開業して以来、約40年に渡り地域の皆様の健康づくりのお役に立ちたいという思いで歩んでまいりました。企業としましては、超高齢化社会の到来に向けた制度改革や、社会情勢の変化など様々な課題はありますが、これからもその思いが変わることはありません。私たちは皆様に寄り添い、「この地域にシンビ堂があって良かった。」と思って頂ける薬局を目指し、日々努力してまいります。
            </div>
          </MessageWrapper>
        </Reveal>
      </MessageContainer>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #00559F;
  color: #fff;
  overflow: hidden;
`

const Title = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 25px;
  `}

  font-size: 1.6rem;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
`

const SubTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    margin-top: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.4rem;
    margin-top: 10px;
  `}

  font-family: 'Kosugi Maru', sans-serif;
    letter-spacing: 0.18em;
    text-align: center;
`

const Content = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto 0;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 0;
    width: 620px;
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px 0 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 20px 0 0 100px;
  `}
`

const DetailWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: start;
    align-items: start;
    margin-bottom: 28px;
  `}
`

const DetailTitle = styled.div`
  ${customMedia.lessThan("medium")`
    border-radius: 12px;
    letter-spacing: 0.24em;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 14px;
    padding: 3px 0;
    letter-spacing: 0.2em;
    width: 115px;
  `}

  background-color: #fff;
  color: #00559F;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
`

const DetailContent = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 10px;
    line-height: 25px;
  `}

  letter-spacing: 0.1em;
  font-size: 1.4rem;
`

const Border = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto;
    width: 800px;
  `}

  height: 2px;
  background-color: #fff;
`

const MessageContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 60px auto 64px;
    height: 600px;
    position: relative;
    width: 800px;
  `}

`

const ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    height: 180px;
    margin-top: 20px;

    img {
      object-fit: cover;
      object-position: 50% 0 !important;
      height: 180px !important;
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 292px;
    top: 0;
    left: 0;
    position: absolute;
    width: 520px;

    img {
      border-radius: 20px;
      object-fit: cover;
      height: 292px !important;
    }
  `}
`

const MessageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: -20px 0 30px 20px;
    padding: 20px;
    position: relative;

    :after, :before{
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 50%;
    }

    :after {
      border-color: rgba(255, 255, 255, 0);
      border-top-width: 16px;
      border-bottom-width: 16px;
      border-left-width: 6px;
      border-right-width: 6px;
      margin-left: -6px;
      border-bottom-color: #fff;
    }

    :before {
      border-color: rgba(0, 85, 159, 0);
      border-top-width: 21px;
      border-bottom-width: 21px;
      border-left-width: 8px;
      border-right-width: 8px;
      margin-left: -8px;
      margin-bottom: 2px;
      border-bottom-color: #00559F;
    }
    
    div:first-child {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
    }

    div:nth-child(2) {
      font-size: 1.4rem;
      letter-spacing: 0.04em;
      line-height: 25px;
      margin-top: 15px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    padding: 40px;
    position: absolute;
    top: 275px;
    right: 0;
    width: 600px;

    :after, :before{
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 32%;
    }

    :after {
      border-color: rgba(255, 255, 255, 0);
      border-top-width: 16px;
      border-bottom-width: 16px;
      border-left-width: 6px;
      border-right-width: 6px;
      margin-left: -6px;
      border-bottom-color: #fff;
    }

    :before {
      border-color: rgba(0, 85, 159, 0);
      border-top-width: 21px;
      border-bottom-width: 21px;
      border-left-width: 8px;
      border-right-width: 8px;
      margin-left: -8px;
      margin-bottom: 2px;
      border-bottom-color: #00559F;
    }

    div:first-child {
      font-size: 2rem;
      letter-spacing: 0.1em;
      font-weight: bold;
    }

    div:nth-child(2) {
      font-size: 1.6rem;
      line-height: 30px;
      margin-top: 15px;
    }
  `}

  background-color: #fff;
  border: 2px #00559F solid;
  border-radius: 20px;
  color: #00559F;
`

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

export default IndexPage
